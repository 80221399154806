import { MutableRefObject, useContext, useEffect, useRef, useState } from 'react'
import { standardHeaders } from '../../entries/utils'
import { Toast } from 'primereact/toast'
import { Link, useParams } from 'react-router-dom'
import { handleNotificationEvent } from '../utils'
import { Dropdown } from 'primereact/dropdown'
import { linkFacebookAccount } from '../utils'
import { CurrentUserContext, DealershipContext } from '../../contexts'
import * as Routes from '../../../routes'
import { User } from '../types'
import Loading from '../../Loading'
import { useFetchDealership } from '../../dataHooks'
import { Dialog } from 'primereact/dialog'

type AdAccount = {
  id: number
  name: string
  dealershipId?: number
  dealershipName?: string
  dealershipSlug?: string
  userName?: string
  userIsAuthorised: boolean
}

type AdAccounts = {
  assignedAccounts: AdAccount[]
  unassignedAccounts: AdAccount[]
}

type Dealership = { id: number; name: string; slug?: string; city?: string; city_slug?: string }

type Provider = 'Facebook' | 'Google'

async function fetchAdAccounts(
  provider: Provider,
  dealershipSlug: string | undefined
): Promise<AdAccounts> {
  try {
    const route = dealershipSlug
      ? `/dealerships/${dealershipSlug}/${provider.toLowerCase()}_ads.json`
      : Routes.ad_accounts_social_ad_accounts_path()

    const response = await fetch(route)
    const data = await response.json()
    return data?.adAccounts
  } catch (error) {
    console.error('Error:', error)
    return {} as AdAccounts
  }
}

async function updateDealershipOnSocialAdAccounts(
  adAccounts: AdAccount[],
  notification: React.MutableRefObject<Toast>,
  isAdmin: boolean,
  isDealership: boolean,
  dealershipId: number
): Promise<void> {
  const exampleId = adAccounts[0]?.id
  const authorisedAdAccounts = isAdmin
    ? adAccounts
    : adAccounts.filter((adAccount) => adAccount.userIsAuthorised)

  try {
    const URL = isDealership
      ? Routes.dealership_social_ad_account_path(dealershipId, exampleId)
      : Routes.bulk_update_social_ad_accounts_path()
    const response = await fetch(URL, {
      method: 'PATCH',
      headers: standardHeaders,
      body: JSON.stringify({ ad_accounts: authorisedAdAccounts }),
    })
    const data = await response.json()
    if (response.ok) handleNotificationEvent(notification, data.message, 'success')
    else handleNotificationEvent(notification, data.message, 'error')
  } catch (error) {
    console.error('Error:', error)
  }
}

const DealershipDropdown: React.FC<{
  adAccountId: number
  updateAdAccountDealership: (adAccountId: number, dealership: Dealership) => void
  dealerships: Dealership[]
}> = ({ adAccountId, updateAdAccountDealership, dealerships }) => {
  const handleChange = (event: { value: Dealership }) => {
    updateAdAccountDealership(adAccountId, event.value)
  }

  return (
    <Dropdown
      placeholder="Set Dealership"
      onChange={handleChange}
      options={dealerships?.map((dealership) => ({
        label: dealership.name,
        value: { id: dealership.id, name: dealership.name },
      }))}
    ></Dropdown>
  )
}

const Table: React.FC<{
  assignedAdAccounts: AdAccount[]
  unassignedAdAccounts: AdAccount[]
  isDealership: boolean
  dealershipId: number
  updateAdAccountDealership: (adAccountId: number, dealership: Dealership) => void
  unAssignAdAccount: (adAccountId: number) => void
  assignAdAccount: (adAccountId: number) => void
  handleDeleteAdAccount: (adAccountId: number) => void
  notification: MutableRefObject<Toast>
  currentUser: User
  provider: Provider
}> = ({
  assignedAdAccounts,
  unassignedAdAccounts,
  isDealership,
  dealershipId,
  updateAdAccountDealership,
  unAssignAdAccount,
  assignAdAccount,
  handleDeleteAdAccount,
  notification,
  currentUser,
  provider,
}) => {
  const [dealerships, setDealerships] = useState<Dealership[]>([])
  const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false)
  const [selectedAdAccountToDelete, setSelectedAdAccountToDelete] = useState<{
    id: number
    name: string
  } | null>(null)

  const userHasAtLeastOneAuthorisation = assignedAdAccounts
    ?.concat(unassignedAdAccounts)
    .some((adAccount) => adAccount.userIsAuthorised)

  const handleRemoveAdAccountDealership = (adAccountId: number) => {
    if (currentUser?.admin) {
      updateAdAccountDealership(adAccountId, {
        id: null,
        name: null,
        city: '',
        city_slug: '',
      })
    } else {
      unAssignAdAccount(adAccountId)
    }
  }

  useEffect(() => {
    if (currentUser?.admin) {
      const fetchDealerships = async () => {
        try {
          const URL = `${Routes.dealership_ids_dealerships_path()}.json`
          const response = await fetch(URL)
          const data: Dealership[] = await response.json()
          setDealerships(data)
        } catch (error) {
          console.error('Error fetching dealerships:', error)
        }
      }

      fetchDealerships()
    }
  }, [currentUser])
  return (
    <>
      <div className="row">
        <div className="col-12">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Ad Acccount Name</th>
                <th>Dealership</th>
                <th>Authorised User</th>
              </tr>
            </thead>
            <tbody>
              {assignedAdAccounts
                ? assignedAdAccounts.map(
                    ({
                      id,
                      name,
                      dealershipId,
                      dealershipName,
                      dealershipSlug,
                      userName,
                      userIsAuthorised,
                    }) => (
                      <tr key={id}>
                        <td>
                          {currentUser?.admin && !dealershipSlug ? (
                            // Currently only the admin panel works for facebook
                            <Link to={`/social_ad_accounts/facebook/${id}`}>{name}</Link>
                          ) : (
                            <Link to={`/dealerships/${dealershipSlug}/social_ad_accounts/${id}`}>
                              {name}
                            </Link>
                          )}
                        </td>
                        <td>
                          {dealershipId === null ? (
                            <DealershipDropdown
                              adAccountId={id}
                              updateAdAccountDealership={updateAdAccountDealership}
                              dealerships={dealerships}
                            />
                          ) : (
                            <div>
                              {dealershipName}
                              {/* Only show this X button if we're in admin view */}
                              {currentUser?.admin || userIsAuthorised ? (
                                <div
                                  className="btn fas fa-times-circle"
                                  style={{ color: 'red' }}
                                  onClick={() => handleRemoveAdAccountDealership(id)}
                                ></div>
                              ) : null}
                            </div>
                          )}
                        </td>
                        <td>{userName}</td>
                      </tr>
                    )
                  )
                : null}
            </tbody>
          </table>
        </div>
      </div>
      {unassignedAdAccounts.length > 0 ? (
        <div className="row mb-4">
          <div className="col-12">
            <h3 className="text-danger">Unassigned Ad Accounts</h3>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Ad Acccount Name</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {unassignedAdAccounts.map(({ id, name }) => (
                  <tr key={id}>
                    <td>{name} </td>
                    <td>
                      {isDealership ? (
                        <a
                          className="mr-4"
                          style={{ cursor: 'pointer' }}
                          onClick={() => assignAdAccount(id)}
                        >
                          Assign
                        </a>
                      ) : (
                        <DealershipDropdown
                          adAccountId={id}
                          updateAdAccountDealership={updateAdAccountDealership}
                          dealerships={dealerships}
                        />
                      )}
                      {provider === 'Facebook' ? (
                        <a
                          className=""
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setSelectedAdAccountToDelete({ id, name })
                            setIsDialogVisible(true)
                          }}
                        >
                          Delete
                        </a>
                      ) : null}
                      <Dialog
                        header={`Are you sure you want to delete the ${selectedAdAccountToDelete?.name} Ad Account?`}
                        visible={isDialogVisible}
                        style={{ width: '50vw', textAlign: 'center' }}
                        onHide={() => setIsDialogVisible(false)}
                        dismissableMask
                      >
                        <i
                          className="far fa-exclamation-triangle"
                          style={{ color: '#fcc600', fontSize: '22px' }}
                        ></i>
                        <h5 className="py-1 ">
                          This is irreversible and you will need to re-link your {provider} account
                          to get access to the Ad Account again.
                        </h5>
                        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                          <button
                            className="btn btn-primary mt-2 "
                            onClick={() => setIsDialogVisible(false)}
                            style={{ width: '150px' }}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn btn-danger mt-2 "
                            onClick={() => {
                              setIsDialogVisible(false)
                              handleDeleteAdAccount(selectedAdAccountToDelete?.id)
                            }}
                            style={{ width: '150px' }}
                          >
                            Delete Ad Account
                          </button>
                        </div>
                      </Dialog>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
      {/*  Only show the save button if admin or if the user has at least one authorised ad account
      There is a check to only update accounts for the authorised user */}
      {currentUser?.admin || userHasAtLeastOneAuthorisation ? (
        <div className="row">
          <div className="col-12">
            <button
              className="btn btn-primary float-right"
              onClick={() =>
                updateDealershipOnSocialAdAccounts(
                  assignedAdAccounts?.concat(unassignedAdAccounts),
                  notification,
                  currentUser?.admin,
                  isDealership,
                  dealershipId
                )
              }
            >
              Save
            </button>
          </div>
        </div>
      ) : null}
    </>
  )
}

const SocialAdAccounts: React.FC<{ provider: Provider; isDealership: boolean }> = ({
  provider,
  isDealership = false,
}) => {
  const currentUser = useContext(CurrentUserContext) as User
  const { dealership } = useContext(DealershipContext) as { dealership: Dealership }
  const notification = useRef<Toast>(null)
  const { dealershipSlug } = useParams<{ dealershipSlug: string }>()
  const [assignedAdAccounts, setAssignedAdAccounts] = useState<AdAccount[]>([])
  const [unassignedAdAccounts, setUnassignedAdAccounts] = useState<AdAccount[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  useFetchDealership()

  const updateAdAccountDealership = (adAccountId: number, dealership: Dealership): void => {
    setAssignedAdAccounts((prevState) =>
      prevState.map((adAccount) =>
        adAccount.id === adAccountId
          ? { ...adAccount, dealershipId: dealership.id, dealershipName: dealership.name }
          : adAccount
      )
    )
  }

  const unAssignAdAccount = (adAccountId: number): void => {
    setAssignedAdAccounts((prevState) =>
      prevState.filter((adAccount) => adAccount.id !== adAccountId)
    )
    setUnassignedAdAccounts((prevUnassignedAccounts) =>
      [
        ...prevUnassignedAccounts,
        ...assignedAdAccounts
          .filter((adAccount) => adAccount.id === adAccountId)
          .map((adAccount) => ({
            ...adAccount,
            dealershipId: null,
            dealershipName: null,
            dealershipSlug: null,
            userName: currentUser?.name,
            userIsAuthorised: true,
          })),
      ].sort((a, b) => a.name.localeCompare(b.name))
    )
  }

  const assignAdAccount = (adAccountId: number): void => {
    setUnassignedAdAccounts((prevState) =>
      prevState.filter((adAccount) => adAccount.id !== adAccountId)
    )
    setAssignedAdAccounts((prevAssignedAccounts) =>
      [
        ...prevAssignedAccounts,
        ...unassignedAdAccounts
          .filter((adAccount) => adAccount.id === adAccountId)
          .map((adAccount) => ({
            ...adAccount,
            dealershipId: dealership.id,
            dealershipName: dealership.name,
            dealershipSlug: dealership.slug,
            userName: currentUser?.name,
            userIsAuthorised: true,
          })),
      ].sort((a, b) => a.name.localeCompare(b.name))
    )
  }

  const handleDeleteAdAccount = async (adAccountId: number) => {
    try {
      const URL = Routes.dealership_social_ad_account_path(dealership.id, adAccountId)
      const response = await fetch(URL, {
        method: 'DELETE',
        headers: standardHeaders,
      })
      const data = await response.json()
      if (response.ok) {
        handleNotificationEvent(notification, data.message, 'success')
        setUnassignedAdAccounts((prevUnassignedAccounts) =>
          prevUnassignedAccounts.filter((adAccount) => adAccount.id !== adAccountId)
        )
      } else {
        handleNotificationEvent(notification, data.message, 'error')
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  // Fetch the ad accounts on initial page load
  useEffect(() => {
    setLoading(true)
    const loadAdAccounts = async () => {
      const accounts = await fetchAdAccounts(provider, dealershipSlug)
      setAssignedAdAccounts(accounts?.assignedAccounts)
      setUnassignedAdAccounts(accounts?.unassignedAccounts)
      setLoading(false)
    }
    loadAdAccounts()
  }, [])

  return (
    <>
      <Toast ref={notification} />
      <div className="container mt-3">
        <div className="row">
          <div className="col-6">
            <h1>{provider} Ad Accounts</h1>
          </div>
          <div className="col-6 m-0">
            {provider === 'Facebook' ? (
              <div
                className="float-right btn btn-outline-primary btn-sm"
                onClick={() => linkFacebookAccount(dealershipSlug)}
              >
                <span>
                  <i className="fab fa-facebook-f mr-2 "></i>Link Facebook Account
                </span>
              </div>
            ) : provider === 'Google' ? (
              <div className="float-right">
                <a
                  className="btn btn-outline-primary btn-sm"
                  href={Routes.authorize_dealership_social_ad_accounts_path(dealershipSlug)}
                >
                  <i className="fab fa-google mr-2"></i>Link Google Account
                </a>
              </div>
            ) : null}
          </div>
        </div>
        {loading ? (
          <div className="d-flex justify-content-center">
            <Loading />
          </div>
        ) : (
          <Table
            assignedAdAccounts={assignedAdAccounts}
            unassignedAdAccounts={unassignedAdAccounts}
            isDealership={isDealership}
            dealershipId={dealership?.id}
            updateAdAccountDealership={updateAdAccountDealership}
            unAssignAdAccount={unAssignAdAccount}
            assignAdAccount={assignAdAccount}
            handleDeleteAdAccount={handleDeleteAdAccount}
            notification={notification}
            currentUser={currentUser}
            provider={provider}
          />
        )}
      </div>
    </>
  )
}

export default SocialAdAccounts
