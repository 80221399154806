import { Link, useParams } from 'react-router-dom'
import { MutableRefObject, useContext, useEffect, useRef, useState } from 'react'
import { Dropdown } from 'primereact/dropdown'
import { standardHeaders } from '../../entries/utils'
import { Toast } from 'primereact/toast'
import { handleNotificationEvent } from '../utils'
import { CurrentUserContext } from '../../contexts'
import * as Routes from '../../../routes'
import { User } from '../types'
import Loading from '../../Loading'

type Campaign = {
  id: number
  name: string
  websiteId: number
  websiteName: string
}

const Table: React.FC<{
  campaigns: Campaign[]
  dealershipId: number
  websites: { id: string; name: string }[]
  updateCampaignWebsite: (campaignId: number, websiteId: number, websiteName: string) => void
  notification: MutableRefObject<Toast>
  adAccountId: string
  canEdit: boolean
}> = ({
  campaigns,
  dealershipId,
  websites,
  updateCampaignWebsite,
  notification,
  adAccountId,
  canEdit,
}) => (
  <>
    <div className="row">
      <div className="col-12">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Campaign</th>
              <th>Website</th>
            </tr>
          </thead>
          <tbody>
            {campaigns.map(({ id, name, websiteId, websiteName }) => (
              <tr key={id}>
                <td>{name}</td>
                <td>
                  {websiteId === null && canEdit ? (
                    <WebsitesDropdown
                      websites={websites}
                      campaignId={id}
                      updateCampaignWebsite={updateCampaignWebsite}
                    />
                  ) : (
                    <div>
                      {websiteName}
                      {canEdit ? (
                        <div
                          className="btn fas fa-times-circle"
                          style={{ color: 'red' }}
                          onClick={() => updateCampaignWebsite(id, null, null)}
                        ></div>
                      ) : null}
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    {/*  Save button */}
    {canEdit ? (
      <div className="row">
        <div className="col-12">
          <button
            className="btn btn-primary float-right"
            onClick={() =>
              updateWebsitesOnCampaign(campaigns, notification, dealershipId, adAccountId)
            }
          >
            Save
          </button>
        </div>
      </div>
    ) : null}
  </>
)

const WebsitesDropdown: React.FC<{
  websites: { id: string; name: string }[]
  campaignId: number
  updateCampaignWebsite: (campaignId: number, websiteId: number, websiteName: string) => void
}> = ({ websites, campaignId, updateCampaignWebsite }) => {
  const handleChange = (event: { value: { id: number; name: string } }) => {
    updateCampaignWebsite(campaignId, event.value.id, event.value.name)
  }
  return (
    <Dropdown
      placeholder="Set Website"
      onChange={handleChange}
      options={websites.map((website) => ({
        label: website.name,
        value: { id: website.id, name: website.name },
      }))}
    ></Dropdown>
  )
}

async function updateWebsitesOnCampaign(
  campaigns: Campaign[],
  notification: MutableRefObject<Toast>,
  dealershipid: number,
  adAccountId: string
): Promise<void> {
  try {
    const URL = dealershipid
      ? Routes.dealership_social_ad_account_path(dealershipid, adAccountId)
      : Routes.bulk_update_social_ad_accounts_path()
    const response = await fetch(URL, {
      method: 'PATCH',
      headers: standardHeaders,
      body: JSON.stringify({ campaigns: campaigns }),
    })
    const data = await response.json()
    if (response.ok) handleNotificationEvent(notification, data.message, 'success')
    else handleNotificationEvent(notification, data.message, 'error')
  } catch (error) {
    handleNotificationEvent(notification, 'An unexpected error occured', 'error')
    console.error('Error:', error)
  }
}

const SocialAdAccount: React.FC<{ isDealership: boolean }> = ({ isDealership = false }) => {
  const notification = useRef<Toast>(null)
  const { dealershipSlug, adAccountId } = useParams<{
    dealershipSlug: string
    adAccountId: string
  }>()
  const [adAccountName, setAdAccountName] = useState<string>('')
  const [provider, setProvider] = useState<string>('')
  const [campaigns, setCampaigns] = useState<Campaign[]>([])
  const [dealershipId, setDealershipId] = useState<number | null>(null)
  const [websites, setWebsites] = useState<{ id: string; name: string }[]>([])
  const [userIsAuthorised, setUserIsAuthorised] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)

  const currentUser = useContext(CurrentUserContext) as User
  const canEdit = userIsAuthorised || currentUser?.admin

  // Update campaign website
  const updateCampaignWebsite = (
    campaignId: number,
    websiteId: number | null,
    websiteName: string | null
  ): void => {
    setCampaigns((prevState) =>
      prevState.map((campaign) =>
        campaign.id === campaignId
          ? {
              ...campaign,
              websiteId: websiteId,
              websiteName: websiteName,
            }
          : campaign
      )
    )
  }

  // fetch ad account and campaigns from id
  async function fetchAdAccountCampaigns(dealershipSlug: string | undefined): Promise<void> {
    setLoading(true)
    try {
      const URL = dealershipSlug
        ? `/dealerships/${dealershipSlug}/social_ad_accounts/${adAccountId}.json`
        : `${Routes.facebook_ad_account_social_ad_accounts_path(adAccountId)}.json`
      const response = await fetch(URL)

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      const data = await response.json()
      setAdAccountName(data.adAccountName)
      setCampaigns(data.campaigns.sort((a, b) => a.name.localeCompare(b.name)))
      setDealershipId(data.dealershipId)
      setProvider(data.provider)
      setWebsites(data.websites.sort((a, b) => a.name.localeCompare(b.name)))
      setUserIsAuthorised(data.userIsAuthorised)
    } catch (error) {
      console.error('Error:', error)
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchAdAccountCampaigns(dealershipSlug)
  }, [adAccountId])

  const adAccountsUrl =
    provider === 'Facebook'
      ? `/dealerships/${dealershipId}/facebook_ads`
      : `/dealerships/${dealershipId}/google_ads`

  return (
    <>
      <Toast ref={notification} />
      <div className="container mt-3">
        <div className="row">
          <div className="col-6">
            <h1>{adAccountName}</h1>
          </div>
          <div className="col-6 m-0">
            {!loading ? (
              <Link
                className="float-right btn btn-outline-primary btn-sm"
                to={isDealership ? adAccountsUrl : '/social_ad_accounts/facebook'}
              >
                Back to Ad Accounts
              </Link>
            ) : null}
          </div>
          <div className="col-12">
            {dealershipId === null && !loading ? (
              <h6 className="text-danger">
                Please add a dealership to this ad account to assign campaigns
              </h6>
            ) : null}
            {dealershipId !== null ? (
              <h6 className="text-danger">
                Note: If a campaign does not have a website assigned, it cannot be used for
                analytics purposes
              </h6>
            ) : null}
          </div>
        </div>
        {campaigns && campaigns.length > 0 && dealershipId !== null ? (
          <>
            <Table
              campaigns={campaigns}
              dealershipId={dealershipId}
              websites={websites}
              updateCampaignWebsite={updateCampaignWebsite}
              notification={notification}
              adAccountId={adAccountId}
              canEdit={canEdit}
            />
          </>
        ) : (
          <div>
            {loading ? (
              <div className="d-flex justify-content-center">
                <Loading />
              </div>
            ) : (
              'No campaigns found for this ad account'
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default SocialAdAccount
