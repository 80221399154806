import {
  createBrowserRouter,
  RouterProvider,
  ScrollRestoration,
  Route,
  createRoutesFromElements,
  Outlet,
} from 'react-router-dom'
// import KeepAlive, { AliveScope } from 'react-activation'
import * as ReactDOM from 'react-dom/client'
import {
  RootContext,
  CurrentUserProvider,
  DealershipProvider,
  WebsiteProvider,
  TestDriveProvider,
} from './contexts'
import AdminBilling from './admin/Billing'
import AdminPushNotifications from './admin/PushNotifications'
import AdminVehicleDetailLookups from './admin/VehicleDetailLookups'
import AdminDealershipPhoneCallsCost from './admin/DealershipPhoneCallsCost'
import AnalyticsDashboard from './AnalyticsDashboard/AnalyticsDashboard'
import AppointmentsCalendar from './appointments/Calendar'
import CarsAutograbIndex from './cars/AutograbIndex'
import CarsEditRedbookDetails from './cars/EditRedbookDetails'
import CarShow from './cars/show'
import CarsalesAccounts from './admin/CarsalesAccounts'
import CarsReports from './cars/Reports'
import CarsSearch from './cars/Search'
import Catalogues from './dealerships/Catalogues'
import ContactsSearch from './contacts/searchContacts'
import DealershipUsersUnavailableIntervals from './unavailableIntervals/dealershipUsersUnavailableIntervals'
import Insurance from './leadCluster/Insurance/Insurance'
import SocialAdAccounts from './AnalyticsDashboard/SocialAdAccounts/SocialAdAccounts'
import SocialAdAccount from './AnalyticsDashboard/SocialAdAccounts/SocialAdAccount'
import IndexPage from './Home/Index'
import LeadClusterShow from './leadCluster/Show'
import LeadClustersSearch from './leadCluster/Search'
import LeadSettings from './settings/leadSettings'
import LeadShow from './leads/Show'
import LeadsSearch from './leads/Search'
import Plugins from './plugins'
import Redbook from './cars/redbook'
import SearchTestDrives from './testDrives/Search'
import StockImageSetEditForm from './StockImageSets/EditForm'
import StockImageSetNewForm from './StockImageSets/NewForm'
import StockImageSetsSearch from './StockImageSets/Search'
import SmartPublisherSettings from './cars/SmartPublisherSettings'
import StockRestrictions from './cars/StockRestrictions'
import TestDriveForm from './testDrives/TestDriveForm'
import UsersSearch from './users/Search'
import WebsitesMenuEditor from './websites/MenuEditor'
import WebsitesRedirects from './websites/Redirects'
import WebsitesSearchPages from './websites/SearchPages'
import Error from './AnalyticsDashboard/Error'
import Orders from './orders/Orders'
import OrderStatusOptions from './orderStatusOptions/OrderStatusOptions'
import ModelSpecificationCategories from './manufacturers/ModelSpecificationCategories'
import ModelSpecifications from './models/ModelSpecifications'

// For Analytics Dashboard
const rootElement = document.getElementById('app_root')
const bodyElement = document.getElementsByTagName('body')[0]
const isAnalytics = rootElement?.hasAttribute('analytics_block')
const ssoEnabled = bodyElement?.getAttribute('sso_enabled') === 'true'
const identityProvidersJson = bodyElement?.getAttribute('identity_providers') || '[]'

let canView = false
let level = ''

if (rootElement && isAnalytics) {
  canView = rootElement.getAttribute('canView') === 'true'
  if (rootElement.getAttribute('Manufacturer')) {
    level = 'Manufacturer'
  } else if (rootElement.getAttribute('Dealership')) {
    level = 'Dealership'
  } else if (rootElement.getAttribute('Website')) {
    level = 'Website'
  }
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      {/* Root route for error handling */}
      <Route
        path="/"
        element={
          <>
            <Outlet />
          </>
        }
        errorElement={<Error />}
      >
        <Route
          path="/"
          element={
            <IndexPage ssoEnabled={ssoEnabled} identityProvidersJson={identityProvidersJson} />
          }
        />
        <Route path="/admin/billing" element={<AdminBilling />} />
        <Route path="/admin/vehicle_detail_lookups" element={<AdminVehicleDetailLookups />} />
        <Route
          path="/admin/dealership_phone_calls_cost"
          element={<AdminDealershipPhoneCallsCost />}
        />
        <Route path="/carsales_accounts" element={<CarsalesAccounts />} />
        <Route
          path="/social_ad_accounts/facebook"
          element={<SocialAdAccounts provider="Facebook" />}
        />
        <Route path="/social_ad_accounts/facebook/:adAccountId" element={<SocialAdAccount />} />
        <Route
          path="/dealerships/:dealershipSlug"
          element={<AnalyticsDashboard canView={canView} level={level} />}
        />
        <Route
          path="/dealerships/:dealershipSlug/appointments"
          element={<AppointmentsCalendar />}
        />
        <Route path="/dealerships/:dealershipSlug/cars/autograb" element={<CarsAutograbIndex />} />
        <Route path="/dealerships/:dealershipSlug/cars/reports" element={<CarsReports />} />
        <Route
          path="/dealerships/:dealershipSlug/cars/:carId/select_vehicle"
          element={<Redbook />}
        />
        <Route path="/dealerships/:dealershipSlug/cars/new" element={<Redbook />} />
        <Route
          path="/dealerships/:dealershipSlug/cars/smart_publisher_settings"
          element={<SmartPublisherSettings />}
        />
        <Route
          path="/dealerships/:dealershipSlug/cars/stock_restrictions"
          element={<StockRestrictions />}
        />
        <Route path="/dealerships/:dealershipSlug/contacts" element={<ContactsSearch />} />
        <Route
          path="/dealerships/:dealershipSlug/dealership_users_unavailable_intervals"
          element={<DealershipUsersUnavailableIntervals />}
        />
        <Route
          path="/dealerships/:dealershipSlug/facebook_ads"
          element={<SocialAdAccounts provider="Facebook" isDealership />}
        />
        <Route
          path="/dealerships/:dealershipSlug/social_ad_accounts/:adAccountId"
          element={<SocialAdAccount isDealership />}
        />
        <Route
          path="/dealerships/:dealershipSlug/google_ads"
          element={<SocialAdAccounts provider="Google" isDealership />}
        />

        <Route
          path="/dealerships/:dealershipSlug/lead_clusters/:leadClusterId/insurance"
          element={<Insurance />}
        />
        <Route path="/dealerships/:dealershipSlug/lead_settings" element={<LeadSettings />} />
        <Route path="/dealerships/:dealershipSlug/plugins" element={<Plugins />} />
        <Route
          path="/dealerships/:dealershipSlug/lead_clusters/pipelines/:pipelineId"
          element={<LeadClustersSearch />}
        />
        <Route
          path="/dealerships/:dealershipSlug/leads/kanban"
          element={<LeadClustersSearch defaultLayout="kanban" />}
        />
        <Route
          path="/dealerships/:dealershipSlug/leads/kanban/pipelines/:pipelineId"
          element={<LeadClustersSearch defaultLayout="kanban" />}
        />
        <Route path="/dealerships/:dealershipSlug/test_drives" element={<SearchTestDrives />} />
        <Route
          path="/dealerships/:dealershipSlug/test_drives/:testDriveId/edit"
          element={
            <TestDriveProvider>
              <TestDriveForm />
            </TestDriveProvider>
          }
        />
        <Route
          path="/dealerships/:dealershipSlug/test_drives/new"
          element={
            <TestDriveProvider>
              <TestDriveForm />
            </TestDriveProvider>
          }
        />
        <Route path="/dealerships/:dealershipSlug/users" element={<UsersSearch />} />
        <Route path="/stock_image_sets" element={<StockImageSetsSearch />} />
        <Route path="/stock_image_sets/:id/edit" element={<StockImageSetEditForm />} />
        <Route path="/stock_image_sets/new" element={<StockImageSetNewForm />} />
        <Route path="/users" element={<UsersSearch />} />
        <Route path="/websites/:websiteSlug/menus" element={<WebsitesMenuEditor />} />
        <Route path="/websites/:websiteSlug/redirects" element={<WebsitesRedirects />} />
        <Route path="/websites/:websiteSlug/search_pages" element={<WebsitesSearchPages />} />
        <Route path="/admin/all_cars" element={<CarsSearch />} />
        <Route path="/admin/push_notifications" element={<AdminPushNotifications />} />
        <Route path="/cars/:carId" element={<CarShow />} />
        <Route path="/dealerships/:dealershipSlug/cars" element={<CarsSearch />} />
        <Route path="/dealerships/:dealershipSlug/facebook_catalogue" element={<Catalogues />} />
        <Route
          path="/dealerships/:dealershipSlug/cars/:carId/edit_redbook_details"
          element={<CarsEditRedbookDetails />}
        />
        <Route path="/dealerships/:dealershipSlug/lead_clusters" element={<LeadClustersSearch />} />
        <Route
          path="/dealerships/:dealershipSlug/lead_clusters/:leadClusterId"
          element={<LeadClusterShow />}
        />
        <Route path="/dealerships/:dealershipSlug/leads" element={<LeadsSearch />} />
        {/* DEPRECATED */}
        <Route path="/dealerships/:dealershipSlug/leads/:leadId" element={<LeadShow />} />
        {/* DEPRECATED */}
        <Route path="/websites/:websiteSlug/leads" element={<LeadClustersSearch />} />
        {/* Will add once website dashboard is live */}
        {/* <Route
        path="/websites/:websiteSlug"
        element={<AnalyticsDashboard canView={canView} level={level} />}
      /> */}
        <Route
          path="/manufacturers/:manufacturerSlug"
          element={<AnalyticsDashboard canView={canView} level={level} />}
        />
        <Route path="/websites/:websiteSlug/orders" element={<Orders />} />
        <Route
          path="/websites/:websiteSlug/order_status_options"
          element={<OrderStatusOptions />}
        />
        <Route
          path="/manufacturers/:manufacturerSlug/model_specification_categories"
          element={<ModelSpecificationCategories />}
        />
        <Route path="/models/:modelSlug/model_specifications" element={<ModelSpecifications />} />
      </Route>
    </>
  )
)

ReactDOM.createRoot(document.getElementById('app_root')).render(
  <RootContext.Provider value={{}}>
    <CurrentUserProvider>
      <DealershipProvider>
        <WebsiteProvider>
          <RouterProvider router={router}>
            <ScrollRestoration />
          </RouterProvider>
        </WebsiteProvider>
      </DealershipProvider>
    </CurrentUserProvider>
  </RootContext.Provider>
)
